import { Controller } from "@hotwired/stimulus";
import URI from "urijs";

export default class extends Controller {
  static values = { lastClickedAt: Number }

  mousedown(event) {
    this.lastClickedAtValue = Date.now()
  }

  submitStart(event) {
    if (this._isSubmitting) this._toggleSubmittingClass(true)
  }

  submitEnd() {
    this._toggleSubmittingClass(false);
  }

  _toggleSubmittingClass(state) {
    this.element.classList.toggle("submitting", state);
  }

  get _isSubmitting() {
    // If the button was clicked in the last 100ms, it should be considered submitting.
    return Date.now() - this.lastClickedAtValue <= 100;
  }
}
