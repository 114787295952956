import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["button", "menu"]
	static values = { menuHidden: Boolean }

	initialize() {
		this.menuHidden = true
  }

  connect() {
  	this.boundDocumentClick = this._documentClick.bind(this)
  	document.addEventListener("click", this.boundDocumentClick)
    this.boundCloseMenu = this.closeMenu.bind(this)
    document.addEventListener("modal:show", this.boundCloseMenu)
  }

  disconnect() {
  	document.removeEventListener("click", this.boundDocumentClick)
    document.removeEventListener("modal:show", this.boundCloseMenu)
  }

	toggleMenu() {
    this.menuHidden = !this.menuHidden
    this.menuTarget.classList.toggle("!hidden", this.menuHidden)
  }

  closeMenu() {
    this.menuHidden = true
    this.menuTarget.classList.toggle("!hidden", true)
  }

  _documentClick(event) {
    const target = event.target || event.srcElement
    if (!target) { return }
    if (this.element.contains(target)) { return }
    if (!this.menuHidden) {
      this.toggleMenu()
    }
  }
}
