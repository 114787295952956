import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.boundClose = this.close.bind(this)
    document.addEventListener("turbo:before-cache", this.boundClose)
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.boundClose)
  }

  backdropClick(event) {
    const target = event.target || event.srcElement
    if (!this.element.firstElementChild.contains(target)) { this.element.close() }
  }

  close() {
    this.element.close()
  }
}
